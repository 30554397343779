import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HostListener } from "@angular/core";
import { environment } from './../environments/environment';
import { ActivatedRoute, Params } from '@angular/router'
export class NgxQrCode {
  text: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public qrdata: string = null
  public apikey: string = ''
  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.qrdata = ""
  }
  ngOnInit() {

    this.getTokenRegister();
    setInterval(() => {
      this.getTokenRegister();
    }, 30000)
  }
  getWidth() {
    return 700
  }

  getTokenRegister() {

    this.route.queryParams.subscribe((params: Params) => {
      let headers = { 'X-Api-Key': params["key"],
      'Content-Type': 'application/json; charset=utf-8'}

      this.http.get("https://api.ddc-care.com/qrcode/1440",{
        headers: headers}).subscribe(data => {
        this.qrdata = environment.url + "?token=" + data['data']['qrcode_key'];
      });
    });

  }
}